import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ClientFormUserRole, ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import ClientService from '../../services/ClientService';
import { ClientFormUser } from '../../models/ClientFormUser';
import { useTranslation } from 'react-i18next';
import UserInvitation from '../../models/UserInvitation';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import AccessControl from './AccessControl';
import { Access } from '../../models/Access';
import AccessDropdown from '../shared/AccessDropdown';
import { useCurrentClient } from '../../global-state/Clients';

type PermissionsModalProps = {
  heading?: string;
  description?: string;
  open: boolean;
  users: ClientFormUser[];
  accessType?: Access;
  onAccesChange?: (vlaue: Access) => void;
  onClose: () => void;
  disableAdd?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAddorUpdate: (users: ClientFormUser[]) => Promise<any>;
  onCreatorRoleChange?: (role: ClientFormUserRoleValues | null) => void;
  requiresApproval?: boolean;
  requiresValidation?: boolean;
  requiresOwner?: boolean;
  shouldValidateRoles?: boolean;
  excludeOwnerRole?: boolean;
  formStepId?: string;
  creatorRole?: ClientFormUserRoleValues;
  canInvite?: boolean;
};

const PermissionsModal: FC<PermissionsModalProps> = (props) => {
  const { t } = useTranslation(['common', 'documents']);

  const {
    open,
    users,
    onClose,
    onAddorUpdate: onSave,
    requiresApproval,
    requiresValidation,
    excludeOwnerRole,
    disableAdd = false,
    heading = t('common:permissions-modal.heading'),
    description,
    formStepId,
    onCreatorRoleChange,
    creatorRole,
    canInvite = true,
    accessType,
    onAccesChange,
    requiresOwner,
    shouldValidateRoles = true,
  } = props;
  const currentClient = useCurrentClient((x) => x.value);
  const [assignedUsers, setAssignedUsers] = useState<ClientFormUser[]>([]);

  const [inviteUserMode, setInviteUserMode] = useState(false);
  const [addUser, setAddUser] = useState<UserInvitation | null>(null);
  const [addUserDisable, setAddUserDisable] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    // reset every time we close
    if (!open) {
      setAssignedUsers(users.filter((x) => (formStepId ? !!x.formSectionId : !x.formSectionId)));
    }
  }, [formStepId, open, users]);

  const inviteUser = useCallback(() => {
    if (currentClient && addUser) {
      setSaving(true);
      ClientService.inviteUser(currentClient?.id, addUser).then((res) => {
        const userId = res.data.userId;
        setSaving(false);
        setAssignedUsers((prev) => {
          return [
            ...prev,
            {
              role: addUser.defaultClientFormRole || ClientFormUserRole.Viewer,
              id: userId,
              email: addUser.email,
              firstName: addUser.firstName ?? '',
              lastName: addUser.lastName ?? '',
              fullName: `${addUser.firstName} ${addUser.lastName}`,
              formSectionId: formStepId,
            },
          ];
        });
        setInviteUserMode(false);
      });
    }
  }, [addUser, currentClient, formStepId]);

  const internalOnClose = useCallback(() => {
    setInviteUserMode(false);
    setSaving(false);
    onClose();
  }, [onClose]);

  const save = () => {
    setSaving(true);
    onSave(assignedUsers).then(() => internalOnClose());
  };

  const onUsersChange = (assignedUsers: ClientFormUser[]) => {
    setAssignedUsers(assignedUsers);
  };

  const isAllRequiredRolesSet = useMemo(() => {
    if (!shouldValidateRoles) {
      return true;
    }
    const requiredRoles: ClientFormUserRoleValues[] = [];
    if (requiresValidation) {
      requiredRoles.push(ClientFormUserRole.Validator);
    }
    if (requiresApproval) {
      requiredRoles.push(ClientFormUserRole.Approver);
    }
    if (requiresOwner) {
      requiredRoles.push(ClientFormUserRole.Owner);
    }

    if (requiredRoles.length === 0) {
      return true;
    }

    for (const role of requiredRoles) {
      const isRoleAssigned = assignedUsers.some((item) => item.role === role);
      if (!isRoleAssigned) {
        return false;
      }
    }

    return true;
  }, [shouldValidateRoles, requiresValidation, requiresApproval, requiresOwner, assignedUsers]);

  return (
    <ModalContext.Provider value={{ open: open, modalWidth: 'xl:w-[800px] w-3/5', onClose: internalOnClose, showCloseButton: false }}>
      <StandardModal
        title={heading || ''}
        subTitle={description || ''}
        cancelButtonTitle={t('common:permissions-modal.manage-cancel')}
        confirmButtonTitle={!inviteUserMode ? t('common:permissions-modal.done') : t('common:permissions-modal.manage-add')}
        onCancelClick={
          !inviteUserMode
            ? internalOnClose
            : () => {
                setInviteUserMode(false);
              }
        }
        onConfirmClick={!inviteUserMode ? save : inviteUser}
        confirmDisabled={!inviteUserMode ? !isAllRequiredRolesSet || saving : addUserDisable || saving}
        tertiaryButtonTitle={!inviteUserMode && canInvite ? t('common:add-or-invite-modal.switch.invite') : ''}
        onTertiaryButtonClick={!inviteUserMode && canInvite && !disableAdd ? () => setInviteUserMode(true) : undefined}
        confirmLoading={saving}
      >
        <div className="min-h-[55vh] w-full">
          {accessType !== undefined && !inviteUserMode && (
            <>
              <div className="relative py-4">
                <AccessDropdown
                  selectedAccess={accessType}
                  onChange={(value) => {
                    onAccesChange && onAccesChange(value);
                  }}
                />
                <div className="pl-6">
                  {t(accessType === Access.restricted ? 'documents:access.restricted' : 'documents:access.client', { client: currentClient?.name })}
                </div>
              </div>
              <div className="text-dpm-16 pb-4 font-medium">{t('documents:access.default-members')}</div>
            </>
          )}
          <AccessControl
            users={assignedUsers}
            onUsersChange={onUsersChange}
            disableAdd={disableAdd}
            requireApprover={requiresApproval}
            requireValidator={requiresValidation}
            requireOwner={requiresOwner}
            creatorRole={creatorRole}
            formStepId={formStepId}
            onCreatorRoleChange={onCreatorRoleChange}
            inviteMode={inviteUserMode}
            onUserInviteValid={(value) => setAddUserDisable(!value)}
            onUserInviteChange={setAddUser}
            excludeOwnerRole={excludeOwnerRole}
            modalIsValid={isAllRequiredRolesSet}
          />
        </div>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default PermissionsModal;
